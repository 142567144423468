/* Info popup styles for privacy policy */
.info-link-gdpr {
  cursor: pointer;
  margin-bottom: 10px;
  border: 0;
  display: block;
  color: $color-periwinkle-blue;
  clear: both;
  text-decoration: underline;
  font-size: 15px;
  letter-spacing: 0.75px;
}

.tools-pages.info-link-gdpr {
  width: 100px;
  margin: 0 auto;
}

.info-icon-gdpr {
  cursor: pointer;
  background: $extended_mpp_black_pearl;
  color: $color-white;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: block;
  text-transform: lowercase;
  @include swap_direction(padding, 1px 0 0 10px);
  line-height: 20px;
}

.info-message-txt-gdpr {
  display: none;
}

.info-msg-gdpr {
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
  z-index: 10000;
  background-color: $color-white;
  padding: 20px;
  border: 1px solid $extended_mpp_black_pearl;
  max-width: 510px;
  width: 90%;
  color: $color-black;
  &__arrow {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    #{$ldirection}: 0;
    border: 10px solid transparent;
    border-bottom-color: $color-periwinkle-blue;
    top: -10px;
    margin-top: -10px;
    &.top-right-arrow {
      left: 478px;
    }
  }
  &__txt {
    position: relative;
    span {
      cursor: pointer;
      color: $color-periwinkle-blue;
      text-decoration: underline $color-periwinkle-blue;
      border: 0;
    }
    a {
      color: $color-periwinkle-blue;
      cursor: pointer;
      text-decoration: underline $color-periwinkle-blue;
      border: 0;
    }
  }
  &__close {
    position: absolute;
    top: -20px;
    #{$rdirection}: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

#registration_short {
  .form-item.new-account__item.checkbox.password {
    .picker.picker-checkbox,
    .password-notice {
      float: #{$ldirection};
    }
    .password-notice {
      margin: 10px 0 0 10px;
    }
  }
}

.info-gdpr-right-icon {
  margin-#{$ldirection}: 10px;
}

.tools-pages.info-link-gdpr[message-in='policy-terms-msg-tools'] {
  width: auto;
}
