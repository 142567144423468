.trust_mark_footer {
  border-top: 1px solid #A4A4AB;
  padding-top: 10px;
  padding-bottom: 10px;
  .trust_mark_footer__link {
    width: 128px;
    height: 145px;
    background-image: url('/media/images/global/lo2-white.png');
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: block;
  }
}

.section-without-gap {
  .content > div {
    margin: 0 auto -5vw auto;
    .module_block {
      margin: 0 auto;
    }
  }
}
