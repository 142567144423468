#content {
  .elc-social-share,
  .product-full__personal {
    display: none;
  }
  .spp-product {
    &__share,
    &__wishlist {
      display: none;
    }
  }
}
