.utility-nav__cart-count,
.utility-nav__cart-button {
  border-left: none;
}

.search-product__wishlist {
  display: none;
}

.footer-signup-email {
  margin-left: 25%;
  @media #{$small-only} {
    margin-left: 0;
  }
}

.quickshop-inline__wishlist {
  display: none;
}

.email-signup {
  text-align: center;
}

#viewcart {
  .samples {
    &__list {
      &-item {
        .sample-img {
          img {
            height: auto;
          }
        }
      }
    }
    &__details {
      margin-top: 40%;
    }
  }
}

.utility-nav__cart {
  @media #{$medium-up} {
    left: 26px;
  }
  @media #{$xlarge-up} {
    left: 50px;
  }
}
