body[data-checkout-release='1'] {
  .column {
    &.right {
      .panel {
        padding: 24px;
      }
    }
  }
  .viewcart-panel {
    .products .product {
      padding: 0;
      width: 99%;
    }
  }
  .pg_wrapper {
    .column.right .need-help-panel.panel {
      padding: 19px 12px;
      @media #{$medium-up} {
        padding: 19px 0;
      }
    }
    .offer-code-panel input[type='submit'] {
      background-color: $color-navy;
      box-shadow: none;
      color: $color-white;
    }
  }
  .footer-legal {
    padding: 16px 0 90px;
  }
}
